// Generated by purs version 0.14.5
"use strict";
var AwHeck_Components_CasedString = require("../AwHeck.Components.CasedString/index.js");
var AwHeck_QueryParams = require("../AwHeck.QueryParams/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var app = function __do() {
    var casedString = AwHeck_Components_CasedString.casedString();
    return React_Basic_Hooks.component("App")(function (props) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(""))(function (v) {
            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffectOnce(function __do() {
                var queryParams = AwHeck_QueryParams.fromWindow(Web_HTML.window)();
                (function () {
                    if (queryParams instanceof Data_Maybe.Just) {
                        return v.value1(function (v1) {
                            return queryParams.value0.value;
                        })();
                    };
                    if (queryParams instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    throw new Error("Failed pattern match at AwHeck.Components.App (line 28, column 11 - line 30, column 33): " + [ queryParams.constructor.name ]);
                })();
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            }))(function () {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                    className: "",
                    children: [ React_Basic_DOM_Generated.h1()({
                        className: "f-headline lh-solid lobster fw4 tc",
                        children: [ React_Basic_DOM.text("Aw Heck") ]
                    }), React_Basic_DOM_Generated.div()({
                        children: [ React_Basic_DOM_Generated.div()({
                            className: "flex",
                            children: [ React_Basic_DOM_Generated.div()({
                                className: "w-0 w-33-ns"
                            }), React_Basic_DOM_Generated.div()({
                                className: "flex w-100 w-33-ns ma4",
                                children: [ React_Basic_DOM_Generated.input()({
                                    className: "input-reset w-100 pa3 br2 bw0 bg-gray white fira-code f4",
                                    value: v.value0,
                                    onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(function (value$prime) {
                                        return v.value1(function (v1) {
                                            return Data_Maybe.fromMaybe("")(value$prime);
                                        });
                                    })
                                }) ]
                            }) ]
                        }) ]
                    }), React_Basic_DOM_Generated.div()({
                        children: [ React_Basic_DOM_Generated.div()({
                            className: "flex flex-wrap",
                            children: Data_Functor.map(Data_Functor.functorArray)(function (casingStyle) {
                                return React_Basic_DOM_Generated.div()({
                                    key: Data_Show.show(AwHeck_Components_CasedString.showCasingStyle)(casingStyle),
                                    className: "w-100 w-50-ns pa4",
                                    children: [ casedString({
                                        style: casingStyle,
                                        value: v.value0
                                    }) ]
                                });
                            })([ AwHeck_Components_CasedString.CamelCase.value, AwHeck_Components_CasedString.PascalCase.value, AwHeck_Components_CasedString.SnakeCase.value, AwHeck_Components_CasedString.ScreamingSnakeCase.value, AwHeck_Components_CasedString.KebabCase.value, AwHeck_Components_CasedString.TitleCase.value ])
                        }) ]
                    }) ]
                }));
            });
        });
    })();
};
module.exports = {
    app: app
};
