// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var JSURI = require("../JSURI/index.js");
var URI_Extra_QueryPairs = require("../URI.Extra.QueryPairs/index.js");
var URI_Query = require("../URI.Query/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var parseQuery = (function () {
    var fromPairs = function (v) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)("value")(v)))(function (value) {
            return new Data_Maybe.Just({
                value: value
            });
        });
    };
    return Control_Bind.composeKleisli(Data_Maybe.bindMaybe)((function () {
        var $2 = URI_Extra_QueryPairs.parse((function () {
            var $4 = Control_Applicative.pure(Data_Either.applicativeEither);
            return function ($5) {
                return $4(URI_Extra_QueryPairs.keyToString($5));
            };
        })())((function () {
            var $6 = Control_Applicative.pure(Data_Either.applicativeEither);
            return function ($7) {
                return $6(URI_Extra_QueryPairs.valueToString($7));
            };
        })());
        return function ($3) {
            return Data_Either.hush($2(URI_Query.fromString($3)));
        };
    })())(fromPairs);
})();
var fromSearch = function (search) {
    var stripQueryPrefix = Data_String_Common.replace("?")("");
    return Control_Bind.bind(Data_Maybe.bindMaybe)(parseQuery(stripQueryPrefix(search)))(function (query) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(JSURI["decodeURIComponent"](query.value))(function (decodedValue) {
            return new Data_Maybe.Just({
                value: decodedValue
            });
        });
    });
};
var fromWindow = function (window) {
    return function __do() {
        var search = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Location.search)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.location)(window))();
        return fromSearch(search);
    };
};
module.exports = {
    fromWindow: fromWindow
};
