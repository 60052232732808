// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var JSURI = require("../JSURI/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var Text_Parsing_Parser_Token = require("../Text.Parsing.Parser.Token/index.js");
var URIPartParseError = function (x) {
    return x;
};
var wrapParser = function (dictMonad) {
    return function (parseA) {
        return function (p) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad)))(Control_Monad_State_Class.get(Control_Monad_Except_Trans.monadStateExceptT(Control_Monad_State_Trans.monadStateStateT(dictMonad))))(function (v) {
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad)))(Data_Newtype.un()(Text_Parsing_Parser.ParserT)(p))(function (a) {
                    var v1 = parseA(a);
                    if (v1 instanceof Data_Either.Left) {
                        return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Control_Monad_State_Trans.monadStateT(dictMonad)))(new Text_Parsing_Parser.ParseError(v1.value0, v.value1));
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Control_Monad_State_Trans.monadStateT(dictMonad)))(v1.value0);
                    };
                    throw new Error("Failed pattern match at URI.Common (line 57, column 3 - line 59, column 21): " + [ v1.constructor.name ]);
                });
            });
        };
    };
};
var subDelims = Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)([ "!", "$", "&", "'", "(", ")", "*", "+", ";", "=", "," ]);
var printEncoded = function (p) {
    return function (s) {
        var simpleChar = Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_CodeUnits.singleton)(p);
        var encodedChar = Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))((function () {
            var $18 = Data_Maybe.fromJust();
            return function ($19) {
                return $18(JSURI["encodeURIComponent"](Data_String_CodeUnits.singleton($19)));
            };
        })())(Text_Parsing_Parser_String.anyChar(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity));
        var parse = Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_Common.joinWith(""))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(simpleChar)(encodedChar))))(Text_Parsing_Parser_String.eof(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity));
        return Data_Either.either(Data_Function["const"](s))(Control_Category.identity(Control_Category.categoryFn))(Text_Parsing_Parser.runParser(s)(parse));
    };
};
var printEncoded$prime = function (p) {
    var $20 = Data_String_NonEmpty_Internal.unsafeFromString();
    var $21 = printEncoded(p);
    return function ($22) {
        return $20($21(Data_String_NonEmpty_Internal.toString($22)));
    };
};
var pctEncoded = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("%"))(function (d0) {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_Token.hexDigit(Data_Identity.monadIdentity))(function (d1) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_Token.hexDigit(Data_Identity.monadIdentity))(function (d2) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Semigroup.append(Data_String_NonEmpty_Internal.semigroupNonEmptyString)(Data_String_NonEmpty_CodeUnits.singleton(d0))(Data_Semigroup.append(Data_String_NonEmpty_Internal.semigroupNonEmptyString)(Data_String_NonEmpty_CodeUnits.singleton(d1))(Data_String_NonEmpty_CodeUnits.singleton(d2))));
        });
    });
});
var ordURIPartParseError = Data_Ord.ordString;
var newtypeURIPartParseError = {
    Coercible0: function () {
        return undefined;
    }
};
var genericURIPartParseError = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showURIPartParseError = {
    show: Data_Show_Generic.genericShow(genericURIPartParseError)(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
        reflectSymbol: function () {
            return "URIPartParseError";
        }
    }))
};
var eqURIPartParseError = Data_Eq.eqString;
var decodeURIComponent$prime = (function () {
    var $23 = Data_String_NonEmpty_Internal.unsafeFromString();
    var $24 = Data_Maybe.fromJust();
    return function ($25) {
        return $23($24(JSURI["decodeURIComponent"](Data_String_NonEmpty_Internal.toString($25))));
    };
})();
var alpha = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(function (c) {
    return c >= "a" && c <= "z" || c >= "A" && c <= "Z";
});
var alphaNum = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(alpha)(Text_Parsing_Parser_Token.digit(Data_Identity.monadIdentity));
var unreserved = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(alphaNum)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("-")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(".")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("_")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("~"));
module.exports = {
    URIPartParseError: URIPartParseError,
    wrapParser: wrapParser,
    alpha: alpha,
    alphaNum: alphaNum,
    unreserved: unreserved,
    pctEncoded: pctEncoded,
    subDelims: subDelims,
    printEncoded: printEncoded,
    "printEncoded'": printEncoded$prime,
    "decodeURIComponent'": decodeURIComponent$prime,
    eqURIPartParseError: eqURIPartParseError,
    ordURIPartParseError: ordURIPartParseError,
    newtypeURIPartParseError: newtypeURIPartParseError,
    genericURIPartParseError: genericURIPartParseError,
    showURIPartParseError: showURIPartParseError
};
