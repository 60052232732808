// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_CodePoint_Unicode = require("../Data.CodePoint.Unicode/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var uncons2 = function (arr) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.uncons(arr))(function (v) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.uncons(v.tail))(function (v1) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                x: v.head,
                y: v1.head,
                rest: v1.tail
            });
        });
    });
};
var stringToMaybe = function (v) {
    if (v === "") {
        return Data_Maybe.Nothing.value;
    };
    return new Data_Maybe.Just(v);
};
var mapTail = function (mapping) {
    return function (arr) {
        var v = Data_Array.uncons(arr);
        if (v instanceof Data_Maybe.Just) {
            return Data_Array.cons(v.value0.head)(Data_Functor.map(Data_Functor.functorArray)(mapping)(v.value0.tail));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return arr;
        };
        throw new Error("Failed pattern match at Data.String.Casing (line 29, column 23 - line 31, column 17): " + [ v.constructor.name ]);
    };
};
var mapHead = function (mapping) {
    return function (arr) {
        var v = Data_Array.uncons(arr);
        if (v instanceof Data_Maybe.Just) {
            return Data_Array.cons(mapping(v.value0.head))(v.value0.tail);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return arr;
        };
        throw new Error("Failed pattern match at Data.String.Casing (line 24, column 23 - line 26, column 17): " + [ v.constructor.name ]);
    };
};
var isSeparator = function (codePoint) {
    var separators = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.codePointFromChar)([ "_", "-", " " ]);
    return Data_Array.elem(Data_String_CodePoints.eqCodePoint)(codePoint)(separators);
};
var isBoundary = function (v) {
    return function (nextChar) {
        if (isSeparator(nextChar)) {
            return true;
        };
        return Data_CodePoint_Unicode.isLower(v) && Data_CodePoint_Unicode.isUpper(nextChar);
    };
};
var getWords = function (value) {
    var getWords$prime = function ($copy_currentWord) {
        return function ($copy_acc) {
            return function ($copy_v) {
                var $tco_var_currentWord = $copy_currentWord;
                var $tco_var_acc = $copy_acc;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(currentWord, acc, v) {
                    if (v.length === 0) {
                        $tco_done = true;
                        return Data_Array.cons(Data_String_CodePoints.fromCodePointArray(currentWord))(acc);
                    };
                    if (v.length === 1) {
                        $tco_done = true;
                        return Data_Array.cons(Data_String_CodePoints.fromCodePointArray(Data_Array.snoc(currentWord)(v[0])))(acc);
                    };
                    var v1 = uncons2(v);
                    if (v1 instanceof Data_Maybe.Just) {
                        var appendCurrentChar = function (word) {
                            var $31 = isSeparator(v1.value0.x);
                            if ($31) {
                                return word;
                            };
                            return Data_Array.concat([ word, [ v1.value0.x ] ]);
                        };
                        var v2 = (function () {
                            var $32 = isBoundary(v1.value0.x)(v1.value0.y);
                            if ($32) {
                                return new Data_Tuple.Tuple("", Data_Array.cons(Data_String_CodePoints.fromCodePointArray(appendCurrentChar(currentWord)))(acc));
                            };
                            var $33 = Data_Array.all(Data_CodePoint_Unicode.isUpper)(currentWord) && (Data_CodePoint_Unicode.isUpper(v1.value0.x) && Data_CodePoint_Unicode.isLower(v1.value0.y));
                            if ($33) {
                                return new Data_Tuple.Tuple(Data_String_CodePoints.fromCodePointArray(appendCurrentChar([  ])), Data_Array.cons(Data_String_CodePoints.fromCodePointArray(currentWord))(acc));
                            };
                            return new Data_Tuple.Tuple(Data_String_CodePoints.fromCodePointArray(appendCurrentChar(currentWord)), acc);
                        })();
                        var remainingChars$prime = (function () {
                            var $35 = !isSeparator(v1.value0.y);
                            if ($35) {
                                return Data_Array.cons(v1.value0.y)(v1.value0.rest);
                            };
                            return v1.value0.rest;
                        })();
                        $tco_var_currentWord = Data_String_CodePoints.toCodePointArray(v2.value0);
                        $tco_var_acc = v2.value1;
                        $copy_v = remainingChars$prime;
                        return;
                    };
                    if (v1 instanceof Data_Maybe.Nothing) {
                        $tco_done = true;
                        return acc;
                    };
                    throw new Error("Failed pattern match at Data.String.Casing (line 61, column 37 - line 89, column 19): " + [ v1.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_currentWord, $tco_var_acc, $copy_v);
                };
                return $tco_result;
            };
        };
    };
    return Data_Array.reverse(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(stringToMaybe)(getWords$prime([  ])([  ])(Data_String_CodePoints.toCodePointArray(value)))));
};
var toKebabCase = (function () {
    var $42 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("-");
    var $43 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $44 = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorArray)(Data_CodePoint_Unicode.toLowerSimple));
    var $45 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($46) {
        return $42($43($44($45(getWords($46)))));
    };
})();
var toScreamingSnakeCase = (function () {
    var $47 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("_");
    var $48 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $49 = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorArray)(Data_CodePoint_Unicode.toUpperSimple));
    var $50 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($51) {
        return $47($48($49($50(getWords($51)))));
    };
})();
var toSnakeCase = (function () {
    var $52 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("_");
    var $53 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $54 = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorArray)(Data_CodePoint_Unicode.toLowerSimple));
    var $55 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($56) {
        return $52($53($54($55(getWords($56)))));
    };
})();
var capitalize = (function () {
    var $57 = mapHead(Data_CodePoint_Unicode.toUpperSimple);
    var $58 = mapTail(Data_CodePoint_Unicode.toLowerSimple);
    return function ($59) {
        return $57($58($59));
    };
})();
var toCamelCase = (function () {
    var $60 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("");
    var $61 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $62 = mapTail(capitalize);
    var $63 = mapHead(Data_Functor.map(Data_Functor.functorArray)(Data_CodePoint_Unicode.toLowerSimple));
    var $64 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($65) {
        return $60($61($62($63($64(getWords($65))))));
    };
})();
var toPascalCase = (function () {
    var $66 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("");
    var $67 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $68 = Data_Functor.map(Data_Functor.functorArray)(capitalize);
    var $69 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($70) {
        return $66($67($68($69(getWords($70)))));
    };
})();
var toTitleCase = (function () {
    var $71 = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ");
    var $72 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.fromCodePointArray);
    var $73 = Data_Functor.map(Data_Functor.functorArray)(capitalize);
    var $74 = Data_Functor.map(Data_Functor.functorArray)(Data_String_CodePoints.toCodePointArray);
    return function ($75) {
        return $71($72($73($74(getWords($75)))));
    };
})();
module.exports = {
    toCamelCase: toCamelCase,
    toPascalCase: toPascalCase,
    toSnakeCase: toSnakeCase,
    toScreamingSnakeCase: toScreamingSnakeCase,
    toKebabCase: toKebabCase,
    toTitleCase: toTitleCase
};
