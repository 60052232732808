// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_Pos = require("../Text.Parsing.Parser.Pos/index.js");
var uncons = function (dict) {
    return dict.uncons;
};
var stripPrefix = function (dict) {
    return dict.stripPrefix;
};
var stringLikeString = {
    uncons: Data_String_CodeUnits.uncons,
    drop: Data_String_CodePoints.drop,
    stripPrefix: Data_String_CodeUnits.stripPrefix,
    "null": Data_String_Common["null"]
};
var string = function (dictStringLike) {
    return function (dictMonad) {
        return function (str) {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
                return v.value0;
            }))(function (input) {
                var v = stripPrefix(dictStringLike)(Data_Newtype.wrap()(str))(input);
                if (v instanceof Data_Maybe.Just) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v1) {
                        return new Text_Parsing_Parser.ParseState(v.value0, Text_Parsing_Parser_Pos.updatePosString(v1.value1)(str), true);
                    }))(function () {
                        return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(str);
                    });
                };
                return Text_Parsing_Parser.fail(dictMonad)("Expected " + Data_Show.show(Data_Show.showString)(str));
            });
        };
    };
};
var $$null = function (dict) {
    return dict["null"];
};
var eof = function (dictStringLike) {
    return function (dictMonad) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
            return v.value0;
        }))(function (input) {
            return Control_Applicative.unless(Text_Parsing_Parser.applicativeParserT(dictMonad))($$null(dictStringLike)(input))(Text_Parsing_Parser.fail(dictMonad)("Expected EOF"));
        });
    };
};
var drop = function (dict) {
    return dict.drop;
};
var anyChar = function (dictStringLike) {
    return function (dictMonad) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
            return v.value0;
        }))(function (input) {
            var v = uncons(dictStringLike)(input);
            if (v instanceof Data_Maybe.Nothing) {
                return Text_Parsing_Parser.fail(dictMonad)("Unexpected EOF");
            };
            if (v instanceof Data_Maybe.Just) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v1) {
                    return new Text_Parsing_Parser.ParseState(v.value0.tail, Text_Parsing_Parser_Pos.updatePosString(v1.value1)(Data_String_CodeUnits.singleton(v.value0.head)), true);
                }))(function () {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v.value0.head);
                });
            };
            throw new Error("Failed pattern match at Text.Parsing.Parser.String (line 56, column 3 - line 63, column 16): " + [ v.constructor.name ]);
        });
    };
};
var satisfy = function (dictStringLike) {
    return function (dictMonad) {
        return function (f) {
            return Text_Parsing_Parser_Combinators.tryRethrow(dictMonad)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(anyChar(dictStringLike)(dictMonad))(function (c) {
                var $56 = f(c);
                if ($56) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(c);
                };
                return Text_Parsing_Parser.fail(dictMonad)("Character '" + (Data_String_CodeUnits.singleton(c) + "' did not satisfy predicate"));
            }));
        };
    };
};
var $$char = function (dictStringLike) {
    return function (dictMonad) {
        return function (c) {
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(function (v) {
                return v === c;
            }))(Data_Show.show(Data_Show.showChar)(c));
        };
    };
};
var noneOf = function (dictStringLike) {
    return function (dictMonad) {
        return function (ss) {
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.notElem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("none of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
        };
    };
};
var oneOf = function (dictStringLike) {
    return function (dictMonad) {
        return function (ss) {
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("one of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
        };
    };
};
var whiteSpace = function (dictStringLike) {
    return function (dictMonad) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(satisfy(dictStringLike)(dictMonad)(function (c) {
            return c === "\x0a" || (c === "\x0d" || (c === " " || c === "\x09"));
        })))(function (cs) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.fromCharArray(cs));
        });
    };
};
var skipSpaces = function (dictStringLike) {
    return function (dictMonad) {
        return Data_Functor["void"](Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(whiteSpace(dictStringLike)(dictMonad));
    };
};
module.exports = {
    drop: drop,
    "null": $$null,
    stripPrefix: stripPrefix,
    uncons: uncons,
    eof: eof,
    string: string,
    anyChar: anyChar,
    satisfy: satisfy,
    "char": $$char,
    whiteSpace: whiteSpace,
    skipSpaces: skipSpaces,
    oneOf: oneOf,
    noneOf: noneOf,
    stringLikeString: stringLikeString
};
