// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var NonEmpty = (function () {
    function NonEmpty(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    NonEmpty.create = function (value0) {
        return function (value1) {
            return new NonEmpty(value0, value1);
        };
    };
    return NonEmpty;
})();
var unfoldable1NonEmpty = function (dictUnfoldable) {
    return {
        unfoldr1: function (f) {
            return function (b) {
                return Data_Tuple.uncurry(NonEmpty.create)(Data_Functor.map(Data_Tuple.functorTuple)(Data_Unfoldable.unfoldr(dictUnfoldable)(Data_Functor.map(Data_Maybe.functorMaybe)(f)))(f(b)));
            };
        }
    };
};
var tail = function (v) {
    return v.value1;
};
var singleton = function (dictPlus) {
    return function (a) {
        return new NonEmpty(a, Control_Plus.empty(dictPlus));
    };
};
var showNonEmpty = function (dictShow) {
    return function (dictShow1) {
        return {
            show: function (v) {
                return "(NonEmpty " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
            }
        };
    };
};
var oneOf = function (dictAlternative) {
    return function (v) {
        return Control_Alt.alt((dictAlternative.Plus1()).Alt0())(Control_Applicative.pure(dictAlternative.Applicative0())(v.value0))(v.value1);
    };
};
var head = function (v) {
    return v.value0;
};
var functorNonEmpty = function (dictFunctor) {
    return {
        map: function (f) {
            return function (m) {
                return new NonEmpty(f(m.value0), Data_Functor.map(dictFunctor)(f)(m.value1));
            };
        }
    };
};
var functorWithIndex = function (dictFunctorWithIndex) {
    return {
        mapWithIndex: function (f) {
            return function (v) {
                return new NonEmpty(f(Data_Maybe.Nothing.value)(v.value0), Data_FunctorWithIndex.mapWithIndex(dictFunctorWithIndex)(function ($151) {
                    return f(Data_Maybe.Just.create($151));
                })(v.value1));
            };
        },
        Functor0: function () {
            return functorNonEmpty(dictFunctorWithIndex.Functor0());
        }
    };
};
var fromNonEmpty = function (f) {
    return function (v) {
        return f(v.value0)(v.value1);
    };
};
var foldableNonEmpty = function (dictFoldable) {
    return {
        foldMap: function (dictMonoid) {
            return function (f) {
                return function (v) {
                    return Data_Semigroup.append(dictMonoid.Semigroup0())(f(v.value0))(Data_Foldable.foldMap(dictFoldable)(dictMonoid)(f)(v.value1));
                };
            };
        },
        foldl: function (f) {
            return function (b) {
                return function (v) {
                    return Data_Foldable.foldl(dictFoldable)(f)(f(b)(v.value0))(v.value1);
                };
            };
        },
        foldr: function (f) {
            return function (b) {
                return function (v) {
                    return f(v.value0)(Data_Foldable.foldr(dictFoldable)(f)(b)(v.value1));
                };
            };
        }
    };
};
var foldableWithIndexNonEmpty = function (dictFoldableWithIndex) {
    return {
        foldMapWithIndex: function (dictMonoid) {
            return function (f) {
                return function (v) {
                    return Data_Semigroup.append(dictMonoid.Semigroup0())(f(Data_Maybe.Nothing.value)(v.value0))(Data_FoldableWithIndex.foldMapWithIndex(dictFoldableWithIndex)(dictMonoid)(function ($152) {
                        return f(Data_Maybe.Just.create($152));
                    })(v.value1));
                };
            };
        },
        foldlWithIndex: function (f) {
            return function (b) {
                return function (v) {
                    return Data_FoldableWithIndex.foldlWithIndex(dictFoldableWithIndex)(function ($153) {
                        return f(Data_Maybe.Just.create($153));
                    })(f(Data_Maybe.Nothing.value)(b)(v.value0))(v.value1);
                };
            };
        },
        foldrWithIndex: function (f) {
            return function (b) {
                return function (v) {
                    return f(Data_Maybe.Nothing.value)(v.value0)(Data_FoldableWithIndex.foldrWithIndex(dictFoldableWithIndex)(function ($154) {
                        return f(Data_Maybe.Just.create($154));
                    })(b)(v.value1));
                };
            };
        },
        Foldable0: function () {
            return foldableNonEmpty(dictFoldableWithIndex.Foldable0());
        }
    };
};
var traversableNonEmpty = function (dictTraversable) {
    return {
        sequence: function (dictApplicative) {
            return function (v) {
                return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(NonEmpty.create)(v.value0))(Data_Traversable.sequence(dictTraversable)(dictApplicative)(v.value1));
            };
        },
        traverse: function (dictApplicative) {
            return function (f) {
                return function (v) {
                    return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(NonEmpty.create)(f(v.value0)))(Data_Traversable.traverse(dictTraversable)(dictApplicative)(f)(v.value1));
                };
            };
        },
        Functor0: function () {
            return functorNonEmpty(dictTraversable.Functor0());
        },
        Foldable1: function () {
            return foldableNonEmpty(dictTraversable.Foldable1());
        }
    };
};
var traversableWithIndexNonEmpty = function (dictTraversableWithIndex) {
    return {
        traverseWithIndex: function (dictApplicative) {
            return function (f) {
                return function (v) {
                    return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(NonEmpty.create)(f(Data_Maybe.Nothing.value)(v.value0)))(Data_TraversableWithIndex.traverseWithIndex(dictTraversableWithIndex)(dictApplicative)(function ($155) {
                        return f(Data_Maybe.Just.create($155));
                    })(v.value1));
                };
            };
        },
        FunctorWithIndex0: function () {
            return functorWithIndex(dictTraversableWithIndex.FunctorWithIndex0());
        },
        FoldableWithIndex1: function () {
            return foldableWithIndexNonEmpty(dictTraversableWithIndex.FoldableWithIndex1());
        },
        Traversable2: function () {
            return traversableNonEmpty(dictTraversableWithIndex.Traversable2());
        }
    };
};
var foldable1NonEmpty = function (dictFoldable) {
    return {
        foldMap1: function (dictSemigroup) {
            return function (f) {
                return function (v) {
                    return Data_Foldable.foldl(dictFoldable)(function (s) {
                        return function (a1) {
                            return Data_Semigroup.append(dictSemigroup)(s)(f(a1));
                        };
                    })(f(v.value0))(v.value1);
                };
            };
        },
        foldr1: function (f) {
            return function (v) {
                return Data_Maybe.maybe(v.value0)(f(v.value0))(Data_Foldable.foldr(dictFoldable)(function (a1) {
                    var $156 = Data_Maybe.maybe(a1)(f(a1));
                    return function ($157) {
                        return Data_Maybe.Just.create($156($157));
                    };
                })(Data_Maybe.Nothing.value)(v.value1));
            };
        },
        foldl1: function (f) {
            return function (v) {
                return Data_Foldable.foldl(dictFoldable)(f)(v.value0)(v.value1);
            };
        },
        Foldable0: function () {
            return foldableNonEmpty(dictFoldable);
        }
    };
};
var foldl1 = function (dictFoldable) {
    return function (dictWarn) {
        return Data_Semigroup_Foldable.foldl1(foldable1NonEmpty(dictFoldable));
    };
};
var eqNonEmpty = function (dictEq1) {
    return function (dictEq) {
        return {
            eq: function (x) {
                return function (y) {
                    return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq1(dictEq1)(dictEq)(x.value1)(y.value1);
                };
            }
        };
    };
};
var ordNonEmpty = function (dictOrd1) {
    return function (dictOrd) {
        return {
            compare: function (x) {
                return function (y) {
                    var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return Data_Ord.compare1(dictOrd1)(dictOrd)(x.value1)(y.value1);
                };
            },
            Eq0: function () {
                return eqNonEmpty(dictOrd1.Eq10())(dictOrd.Eq0());
            }
        };
    };
};
var eq1NonEmpty = function (dictEq1) {
    return {
        eq1: function (dictEq) {
            return Data_Eq.eq(eqNonEmpty(dictEq1)(dictEq));
        }
    };
};
var ord1NonEmpty = function (dictOrd1) {
    return {
        compare1: function (dictOrd) {
            return Data_Ord.compare(ordNonEmpty(dictOrd1)(dictOrd));
        },
        Eq10: function () {
            return eq1NonEmpty(dictOrd1.Eq10());
        }
    };
};
module.exports = {
    NonEmpty: NonEmpty,
    singleton: singleton,
    foldl1: foldl1,
    fromNonEmpty: fromNonEmpty,
    oneOf: oneOf,
    head: head,
    tail: tail,
    showNonEmpty: showNonEmpty,
    eqNonEmpty: eqNonEmpty,
    eq1NonEmpty: eq1NonEmpty,
    ordNonEmpty: ordNonEmpty,
    ord1NonEmpty: ord1NonEmpty,
    functorNonEmpty: functorNonEmpty,
    functorWithIndex: functorWithIndex,
    foldableNonEmpty: foldableNonEmpty,
    foldableWithIndexNonEmpty: foldableWithIndexNonEmpty,
    traversableNonEmpty: traversableNonEmpty,
    traversableWithIndexNonEmpty: traversableWithIndexNonEmpty,
    foldable1NonEmpty: foldable1NonEmpty,
    unfoldable1NonEmpty: unfoldable1NonEmpty
};
