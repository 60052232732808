// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var JSURI = require("../JSURI/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var URI_Common = require("../URI.Common/index.js");
var Query = function (x) {
    return x;
};
var unsafeToString = function (v) {
    return v;
};
var unsafeFromString = Query;
var toString = function (v) {
    return Data_Maybe.fromJust()(JSURI["decodeURIComponent"](v));
};
var showQuery = {
    show: function (v) {
        return "(Query.unsafeFromString " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var semigroupQuery = Data_Semigroup.semigroupString;
var queryChar = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(URI_Common.unreserved)(URI_Common.subDelims))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(":")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("@")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("/")))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("?"));
var print = function (v) {
    return "?" + v;
};
var parser = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("?"))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))((function () {
    var $9 = Data_String_NonEmpty_Internal.joinWith(Data_Foldable.foldableArray)("");
    return function ($10) {
        return Query($9($10));
    };
})())(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_NonEmpty_CodeUnits.singleton)(queryChar))(URI_Common.pctEncoded))));
var ordQuery = Data_Ord.ordString;
var monoidQuery = Data_Monoid.monoidString;
var fromString = (function () {
    var $11 = URI_Common.printEncoded(queryChar);
    return function ($12) {
        return Query($11($12));
    };
})();
var eqQuery = Data_Eq.eqString;
module.exports = {
    fromString: fromString,
    toString: toString,
    unsafeFromString: unsafeFromString,
    unsafeToString: unsafeToString,
    parser: parser,
    print: print,
    queryChar: queryChar,
    eqQuery: eqQuery,
    ordQuery: ordQuery,
    semigroupQuery: semigroupQuery,
    monoidQuery: monoidQuery,
    showQuery: showQuery
};
