// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Casing = require("../Data.String.Casing/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var CamelCase = (function () {
    function CamelCase() {

    };
    CamelCase.value = new CamelCase();
    return CamelCase;
})();
var PascalCase = (function () {
    function PascalCase() {

    };
    PascalCase.value = new PascalCase();
    return PascalCase;
})();
var SnakeCase = (function () {
    function SnakeCase() {

    };
    SnakeCase.value = new SnakeCase();
    return SnakeCase;
})();
var ScreamingSnakeCase = (function () {
    function ScreamingSnakeCase() {

    };
    ScreamingSnakeCase.value = new ScreamingSnakeCase();
    return ScreamingSnakeCase;
})();
var KebabCase = (function () {
    function KebabCase() {

    };
    KebabCase.value = new KebabCase();
    return KebabCase;
})();
var TitleCase = (function () {
    function TitleCase() {

    };
    TitleCase.value = new TitleCase();
    return TitleCase;
})();
var showCasingStyle = {
    show: function (v) {
        if (v instanceof CamelCase) {
            return "CamelCase";
        };
        if (v instanceof PascalCase) {
            return "PascalCase";
        };
        if (v instanceof SnakeCase) {
            return "SnakeCase";
        };
        if (v instanceof ScreamingSnakeCase) {
            return "ScreamingSnakeCase";
        };
        if (v instanceof KebabCase) {
            return "KebabCase";
        };
        if (v instanceof TitleCase) {
            return "TitleCase";
        };
        throw new Error("Failed pattern match at AwHeck.Components.CasedString (line 21, column 10 - line 27, column 29): " + [ v.constructor.name ]);
    }
};
var eqCasingStyle = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CamelCase && y instanceof CamelCase) {
                return true;
            };
            if (x instanceof PascalCase && y instanceof PascalCase) {
                return true;
            };
            if (x instanceof SnakeCase && y instanceof SnakeCase) {
                return true;
            };
            if (x instanceof ScreamingSnakeCase && y instanceof ScreamingSnakeCase) {
                return true;
            };
            if (x instanceof KebabCase && y instanceof KebabCase) {
                return true;
            };
            if (x instanceof TitleCase && y instanceof TitleCase) {
                return true;
            };
            return false;
        };
    }
};
var casedString = (function () {
    var toCase = function (v) {
        if (v instanceof CamelCase) {
            return Data_String_Casing.toCamelCase;
        };
        if (v instanceof PascalCase) {
            return Data_String_Casing.toPascalCase;
        };
        if (v instanceof SnakeCase) {
            return Data_String_Casing.toSnakeCase;
        };
        if (v instanceof ScreamingSnakeCase) {
            return Data_String_Casing.toScreamingSnakeCase;
        };
        if (v instanceof KebabCase) {
            return Data_String_Casing.toKebabCase;
        };
        if (v instanceof TitleCase) {
            return Data_String_Casing.toTitleCase;
        };
        throw new Error("Failed pattern match at AwHeck.Components.CasedString (line 60, column 12 - line 66, column 36): " + [ v.constructor.name ]);
    };
    var sanitize = Data_String_Common.replaceAll(".")("_");
    return React_Basic_Hooks.component("CasedString")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
            className: "pa4 bg-dark-gray br4",
            children: [ React_Basic_DOM_Generated.h3()({
                className: "ma0 mb2 f3 fw4 fira-code",
                children: [ React_Basic_DOM.text(toCase(v.style)(Data_Show.show(showCasingStyle)(v.style))) ]
            }), React_Basic_DOM_Generated.div()({
                className: "flex",
                children: [ React_Basic_DOM_Generated.input()({
                    className: "input-reset pa2 w-100 bw0 br2 bg-gray white fira-code",
                    readOnly: true,
                    value: toCase(v.style)(sanitize(v.value))
                }) ]
            }) ]
        }));
    });
})();
module.exports = {
    CamelCase: CamelCase,
    PascalCase: PascalCase,
    SnakeCase: SnakeCase,
    ScreamingSnakeCase: ScreamingSnakeCase,
    KebabCase: KebabCase,
    TitleCase: TitleCase,
    casedString: casedString,
    eqCasingStyle: eqCasingStyle,
    showCasingStyle: showCasingStyle
};
