// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var JSURI = require("../JSURI/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var URI_Common = require("../URI.Common/index.js");
var URI_Query = require("../URI.Query/index.js");
var Value = function (x) {
    return x;
};
var QueryPairs = function (x) {
    return x;
};
var Key = function (x) {
    return x;
};
var valueToString = function (v) {
    return Data_Maybe.fromJust()(JSURI["decodeURIComponent"](v));
};
var unsafeValueToString = function (v) {
    return v;
};
var unsafeValueFromString = Value;
var unsafeKeyToString = function (v) {
    return v;
};
var unsafeKeyFromString = Key;
var showValue = {
    show: function (v) {
        return "(QueryPairs.unsafeValueFromString " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var showKey = {
    show: function (v) {
        return "(QueryPairs.unsafeKeyFromString " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var semigroupValue = Data_Semigroup.semigroupString;
var semigroupQueryPairs = Data_Semigroup.semigroupArray;
var semigroupKey = Data_Semigroup.semigroupString;
var print = function (printK) {
    return function (printV) {
        return function (v) {
            var printPart = function (v1) {
                if (v1.value1 instanceof Data_Maybe.Nothing) {
                    return unsafeKeyToString(printK(v1.value0));
                };
                if (v1.value1 instanceof Data_Maybe.Just) {
                    return unsafeKeyToString(printK(v1.value0)) + ("=" + unsafeValueToString(printV(v1.value1.value0)));
                };
                throw new Error("Failed pattern match at URI.Extra.QueryPairs (line 102, column 17 - line 106, column 78): " + [ v1.constructor.name ]);
            };
            return URI_Query.unsafeFromString(Data_String_Common.joinWith("&")(Data_Array.fromFoldable(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(printPart)(v))));
        };
    };
};
var ordValue = Data_Ord.ordString;
var ordQueryPairs = function (dictOrd) {
    return function (dictOrd1) {
        return Data_Ord.ordArray(Data_Tuple.ordTuple(dictOrd)(Data_Maybe.ordMaybe(dictOrd1)));
    };
};
var ordKey = Data_Ord.ordString;
var monoidValue = Data_Monoid.monoidString;
var monoidQueryPairs = Data_Monoid.monoidArray;
var monoidKey = Data_Monoid.monoidString;
var keyToString = function (v) {
    return Data_Maybe.fromJust()(JSURI["decodeURIComponent"](v));
};
var keyPartChar = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(URI_Common.unreserved)(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)([ "!", "$", "'", "(", ")", "*", "+", ",", ":", "@", "/", "?" ]));
var valueFromString = (function () {
    var $39 = URI_Common.printEncoded(keyPartChar);
    return function ($40) {
        return Value($39($40));
    };
})();
var valuePartChar = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(keyPartChar)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("="));
var parsePart = function (parseK) {
    return function (parseV) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(URI_Common.wrapParser(Data_Identity.monadIdentity)(function ($41) {
            return parseK(Key($41));
        })(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_NonEmpty_Internal.joinWith(Data_Foldable.foldableArray)(""))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_NonEmpty_CodeUnits.singleton)(keyPartChar))(URI_Common.pctEncoded)))))(function (key) {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(URI_Common.wrapParser(Data_Identity.monadIdentity)(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Data_Either.applicativeEither)(function ($42) {
                return parseV(Value($42));
            }))(Text_Parsing_Parser_Combinators.optionMaybe(Data_Identity.monadIdentity)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("="))(function () {
                return Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_NonEmpty_Internal.joinWith(Data_Foldable.foldableArray)(""))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_NonEmpty_CodeUnits.singleton)(valuePartChar))(URI_Common.pctEncoded)));
            }))))(function (value) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(new Data_Tuple.Tuple(key, value));
            });
        });
    };
};
var parse = function (parseK) {
    return function (parseV) {
        var $43 = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(function (v) {
            return v.value0;
        })(QueryPairs);
        var $44 = Data_Function.flip(Text_Parsing_Parser.runParser)(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_Array.fromFoldable(Data_List_Types.foldableList))(Text_Parsing_Parser_Combinators.sepBy(Data_Identity.monadIdentity)(parsePart(parseK)(parseV))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("&"))));
        return function ($45) {
            return $43($44(URI_Query.unsafeToString($45)));
        };
    };
};
var keyFromString = (function () {
    var $46 = URI_Common.printEncoded(keyPartChar);
    return function ($47) {
        return Key($46($47));
    };
})();
var genericQueryPairs = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showQueryPairs = function (dictShow) {
    return function (dictShow1) {
        return {
            show: Data_Show_Generic.genericShow(genericQueryPairs)(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showArray(Data_Tuple.showTuple(dictShow)(Data_Maybe.showMaybe(dictShow1)))))({
                reflectSymbol: function () {
                    return "QueryPairs";
                }
            }))
        };
    };
};
var eqValue = Data_Eq.eqString;
var eqQueryPairs = function (dictEq) {
    return function (dictEq1) {
        return Data_Eq.eqArray(Data_Tuple.eqTuple(dictEq)(Data_Maybe.eqMaybe(dictEq1)));
    };
};
var eqKey = Data_Eq.eqString;
module.exports = {
    QueryPairs: QueryPairs,
    parse: parse,
    print: print,
    keyPartChar: keyPartChar,
    valuePartChar: valuePartChar,
    keyFromString: keyFromString,
    keyToString: keyToString,
    unsafeKeyFromString: unsafeKeyFromString,
    unsafeKeyToString: unsafeKeyToString,
    valueFromString: valueFromString,
    valueToString: valueToString,
    unsafeValueFromString: unsafeValueFromString,
    unsafeValueToString: unsafeValueToString,
    eqQueryPairs: eqQueryPairs,
    ordQueryPairs: ordQueryPairs,
    genericQueryPairs: genericQueryPairs,
    showQueryPairs: showQueryPairs,
    semigroupQueryPairs: semigroupQueryPairs,
    monoidQueryPairs: monoidQueryPairs,
    eqKey: eqKey,
    ordKey: ordKey,
    semigroupKey: semigroupKey,
    monoidKey: monoidKey,
    showKey: showKey,
    eqValue: eqValue,
    ordValue: ordValue,
    semigroupValue: semigroupValue,
    monoidValue: monoidValue,
    showValue: showValue
};
