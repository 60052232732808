// Generated by purs version 0.14.5
"use strict";
var AwHeck_Components_App = require("../AwHeck.Components.App/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var main = function __do() {
    var appRoot = Control_Bind.bindFlipped(Effect.bindEffect)(Web_DOM_NonElementParentNode.getElementById("app"))(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toNonElementParentNode)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window)))();
    var app = AwHeck_Components_App.app();
    if (appRoot instanceof Data_Maybe.Just) {
        return React_Basic_DOM.render(app({}))(appRoot.value0)();
    };
    if (appRoot instanceof Data_Maybe.Nothing) {
        return Effect_Exception["throw"]("App root not found!")();
    };
    throw new Error("Failed pattern match at Main (line 18, column 3 - line 20, column 43): " + [ appRoot.constructor.name ]);
};
module.exports = {
    main: main
};
